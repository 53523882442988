<template>
  <div>
    <div class="tab">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane v-for="item in pane" :key="item.name" :tab="item.label">
        </a-tab-pane>
      </a-tabs>
      <div class="reoprtclass" v-show="activeKey === 'Normal'">
        <a-popover placement="bottomRight">
          <template #content>
            <a-table
                :columns="demandColumns"
                :data-source="demandData"
                :pagination="false"
            ></a-table>
          </template>
          <div>
            <span style="margin-right: 0.2vw">说明</span>
            <QuestionCircleOutlined />
          </div>
        </a-popover>
      </div>
    </div>
    <div class="tab-container" v-if="activeKey">
      <component  :is="activeKey" />
    </div>
  </div>
</template>
<script>
import {computed, defineComponent, ref} from "vue";
import PipeLine from "./cpns/pipeline.vue"
import Normal from "./cpns/normal.vue"
import Custom from "./cpns/custom.vue"
import {QuestionCircleOutlined} from "@ant-design/icons-vue";
import {isShowTab} from "@/utils/isshow";
export default defineComponent({
  name: "matchingInfoList",
  components: {
    QuestionCircleOutlined,
    PipeLine,
    Normal,
    Custom
  },
  setup() {
    const activeKey = ref('PipeLine')

    const pane = computed(() => {
      let arr = []
      if (pipeLineShow) arr.push({ label: 'Pipeline', name: 'PipeLine' })
      if (normalShow) arr.push({ label: '北森需求', name: 'Normal' })
      if (customShow) arr.push({ label: '自定义需求', name: 'Custom' })
      return arr
    })

    //北森需求说明
    const demandColumns = [
      {
        title: "RMS获取北森需求逻辑：",
        dataIndex: "explain",
        key: "explain",
      },
    ];
    const demandData = [
      {
        explain:
            "1.需求状态进行中，RM选择资源团队含【资源团队-Internal Own】/【资源团队-Internal JTP】",
      },
      {
        explain:
            "2.需求状态已完成，实际报道日期“近60天”（含前后），完成渠道含【TA_Team、Internal Own、Internal JTP】",
      },
    ];

    //pipeLine,北森,自定义 tab标签页权限配置
    const pipeLineShow = isShowTab('/system/matchingInfoList/pipeLineTab')
    const normalShow = isShowTab('/system/matchingInfoList/normalTab')
    const customShow = isShowTab('/system/matchingInfoList/customTab')

    return {
      activeKey,
      pane,
      demandColumns,
      demandData,
      pipeLineShow,
      normalShow,
      customShow,
    }
  },
});
</script>
<style scoped lang="less">
.tab {
  position: relative;
  :deep(.ant-tabs-tab) {
    color: rgb(153, 153, 153) !important;
    font-weight: 700;
  }

  :deep(.ant-tabs-top-bar) {
    margin-bottom: 0px !important;
  }

  :deep(.ant-tabs-tab-active) {
    color: rgb(49, 130, 206) !important;
    font-weight: 700;
  }

  :deep(.ant-tabs-content) {
    display: none !important;
  }

  .reoprtclass {
    position: absolute;
    top: 8px;
    right: 5px;
    color: #3182ce;
    font-size: 15px;
  }
}
.tab-container {
  width: 100%;
  height: 82vh;
  overflow: hidden;
  background-color: white;
  border-radius: 6px;
  padding: 0px 20px;
}
</style>
