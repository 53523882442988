<template>
    <div>
        <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @showMoreForm="showMoreForm"
            :moreBtnVisible="moreBtnVisible" :isShowMoreFlag="isShowMoreList">
            <template #customform>
                <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
                    <template v-for="(item, index) in queryList" :key="index">
                        <template v-if="index <= 3">
                            <a-form-item :label="item.code" name="name" class="rms-form-item">
                                <template v-if="item.type === '2'">
                                    <a-select allow-clear v-model:value="formState.isFollowUp" placeholder="请选择">
                                        <a-select-option key="Y" value="1">Y</a-select-option>
                                        <a-select-option key="N" value="0">N</a-select-option>
                                    </a-select>
                                </template>
                                <template v-if="item.type === '3'">
                                    <a-input v-model:value="formState[item.inputDataType]"
                                        :placeholder="item.inputDataType === 'condition' ? 'Opportunity Name/PPL ID/THOR ID' : 'Name/GGID'" />
                                </template>
                                <template
                                    v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['subPl', 'account'].includes(item.inputDataType)">
                                    <SelectWithAll :maxTagCount="2" v-model:value="formState[item.inputDataType]"
                                        placeholder="请选择" mode="multiple" showArrow allowClear
                                        :options="getOptions(item.inputDataType)" @change="orgChange(item.inputDataType)">
                                    </SelectWithAll>
                                </template>
                                <template
                                    v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['cst', 'offering'].includes(item.inputDataType)">
                                    <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                        mode="multiple" showArrow :maxTagCount="2" allowClear
                                        :options="getOptions(item.inputDataType)" @change="orgChange(item.inputDataType)">
                                    </SelectWithAll>
                                </template>
                                <template v-if="item.type === '5' && item.inputDataType == 'biddingStatus'">
                                    <a-select mode="multiple" :max-tag-count="1" allow-clear
                                        v-model:value="formState.biddingStatus" placeholder="请选择">
                                        <a-select-option key="Won" value="1">Won</a-select-option>
                                        <a-select-option key="Dropped" value="2">Dropped</a-select-option>
                                        <a-select-option key="CG Qualified Out" value="3">CG Qualified Out</a-select-option>
                                        <a-select-option key="Bidding" value="4">Bidding</a-select-option>
                                        <a-select-option key="Leads" value="5">Leads</a-select-option>
                                        <a-select-option key="Lost" value="6">Lost</a-select-option>
                                    </a-select>
                                </template>
                            </a-form-item>
                        </template>
                    </template>
                    <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm"
                        @moreFormSearch="moreSeachHanlder">
                        <template v-for="(item, index) in queryList" :key="index">
                            <template v-if="index > 3">
                                <a-form-item :label="item.code" name="name" class="rms-form-item">
                                    <template v-if="item.type === '2'">
                                        <a-select allow-clear v-model:value="formState.isFollowUp" placeholder="请选择">
                                            <a-select-option key="Y" value="1">Y</a-select-option>
                                            <a-select-option key="N" value="0">N</a-select-option>
                                        </a-select>
                                    </template>
                                    <template v-if="item.type === '3'">
                                        <a-input v-model:value="formState[item.inputDataType]"
                                            :placeholder="item.inputDataType === 'condition' ? 'Opportunity Name/PPL ID/THOR ID' : 'Name/GGID'" />
                                    </template>
                                    <template
                                        v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['subPl', 'account'].includes(item.inputDataType)">
                                        <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                            mode="multiple" showArrow :maxTagCount="2" allowClear
                                            :options="getOptions(item.inputDataType)"
                                            @change="orgChange(item.inputDataType)">
                                        </SelectWithAll>
                                    </template>
                                    <template
                                        v-if="item.type === '5' && item.inputDataType != 'biddingStatus' && ['cst', 'offering'].includes(item.inputDataType)">
                                        <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                            mode="multiple" showArrow :maxTagCount="2" allowClear
                                            :options="getOptions(item.inputDataType)"
                                            @change="orgChange(item.inputDataType)">
                                        </SelectWithAll>
                                    </template>
                                    <template v-if="item.type === '5' && item.inputDataType == 'biddingStatus'">
                                        <a-select :max-tag-count="1" mode="multiple" allow-clear
                                            v-model:value="formState.biddingStatus" placeholder="请选择">
                                            <a-select-option key="Won" value="1">Won</a-select-option>
                                            <a-select-option key="Dropped" value="2">Dropped</a-select-option>
                                            <a-select-option key="CG Qualified Out" value="3">CG Qualified
                                                Out</a-select-option>
                                            <a-select-option key="Bidding" value="4">Bidding</a-select-option>
                                            <a-select-option key="Leads" value="5">Leads</a-select-option>
                                            <a-select-option key="Lost" value="6">Lost</a-select-option>
                                        </a-select>
                                    </template>
                                </a-form-item>
                            </template>
                        </template>
                    </MoreFormList>
                </a-form>
            </template>
            <template #operationBtns>
                <div class="buttons-wrap right">
                    <Tooltip title="导出">
                        <a-button class="" type="primary" ghost @click="exportClick" v-auth="['system:pipeline:export']">
                            <ExportOutlined />
                        </a-button>
                    </Tooltip>
                    <Tooltip title="导入">
                        <a-button class="ml12" type="primary" ghost @click="uploadClick"
                            v-auth="['system:pipeline:import']">
                            <ImportOutlined />
                        </a-button>
                    </Tooltip>
                    <Tooltip title="新建">
                        <a-button class="ml12" type="primary" @click="addPipelineClick('add')"
                            v-auth="['system:pipeline:add']">
                            <PlusOutlined />
                        </a-button>
                    </Tooltip>
                </div>
            </template>
        </FilterLayout>
        <a-table rowKey="id" ref="tableRef" :scroll="{ x: 3000, y: '53vh' }" :columns="columns" :data-source="tableData"
            :pagination="pagination">
            <template #operation="{ record }">
                <ActionBtn v-bind="record" :btnList="btnList" @EyeOutlined="addPipelineClick('check', record)"
                    @FormOutlined="addPipelineClick('edit', record)" />
            </template>
        </a-table>
        <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="false" :checkList="checkList" @closeModal="closeModal"
            @reset="queryFormListHandler"></FormModalBox>
    </div>
    <PipelineModal :visible="pipelineVisible" :optType="optType" :pipelineInfo="pipelineInfo" :paramListObj="paramListObj"
        @close-modal="closePipelineModal" @submit-modal="submitPipelineModal" :nowStep="0"/>
    <import-modal v-model:visible="importModalVisible" apiFuncName="importPipeline" @closeModal="handleCloseModal"
        @resetTable="doUpdateImport" />
</template>
<script>
import { defineComponent, onMounted, reactive, ref, toRefs } from "vue";
import { extraListHandler, useHandler } from '../hooks/hooks'
import MoreFormList from '@/components/MoreFormList'
import { useGlobalPropertyHook } from "@/hooks/common.js";
import FormModalBox from '@/components/FormModalBox'
import { ExportOutlined, ImportOutlined, PlusOutlined } from "@ant-design/icons-vue";
import { cloneDeep } from "lodash";
import { dictionaryOpt } from '@/utils/common'
import PipelineModal from "@/views/home/ResourceManagement/Match/matchingInfoList/cpns/components/PipelineModal.vue";
import ActionBtn from "@/components/ActionBtn.vue";
import Tooltip from "@/components/Tooltip.vue";
import ImportModal from "@/components/ImportModal.vue";
import { message } from "ant-design-vue";

const btnList = [
    {
        name: '需求详情',
        icon: 'EyeOutlined',
        auth: ['system:pipeline:check']
    },
    {
        name: '编辑',
        icon: 'FormOutlined',
        auth: ['system:pipeline:edit']
    },
]
export default defineComponent({
    name: "pipeline",
    components: {
        ImportModal,
        ImportOutlined,
        ExportOutlined,
        Tooltip,
        ActionBtn, PipelineModal, PlusOutlined, MoreFormList, FormModalBox,
    },
    setup() {
        const { $api } = useGlobalPropertyHook();
        const {
            moreBtnVisible, isShowFormSortBox, isShowMoreList, checkList,
            showMoreForm, closeMoreForm, openFormSortBox, closeModal, queryList, queryFormList
        } = extraListHandler()

        onMounted(() => {
            queryFormListHandler({ isQueryDefault: 0, isQuery: true })
            getSelectOptions()
            doSearch()
        })

        const queryFormListHandler = (data) => {
            queryFormList(data, 'resourceManagement', 'demandInfo', 3)
        }

        let initData = {
            condition: '',
            subPl: [],
            biddingStatus: [],
            account: [],
            cst: [],
            offering: [],
            salescCsmSpoc: '',
            isFollowUp: undefined
        }

        let formState = reactive({ ...initData })
        const collapseCellFunc = () => {
            return {
                style: {
                    "border-right": '5px solid rgb(255, 255, 255)',
                }
            };
        };
        const firstHeader = () => {
            return {
                style: {
                    "border-right": '5px solid rgb(255, 255, 255)',
                    "border-bottom": '5px solid rgb(255, 255, 255)'
                }
            };
        }
        const leftHeader = () => {
            return {
                style: {
                    "border-left": '5px solid rgb(255, 255, 255)',
                }
            };
        }
        const columns = [
            {
                title: 'Basic Information', children: [
                    {
                        title: 'Pipeline ID',
                        dataIndex: 'pipelineId',
                        key: 'pipelineId',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Sub P&L',
                        dataIndex: 'subPl',
                        key: 'subPl',
                        width: 310,
                        ellipsis: true,
                    },
                    {
                        title: 'Account',
                        dataIndex: 'account',
                        key: 'account',
                        width: 330,
                        ellipsis: true,
                    },
                    {
                        title: 'Opportunity ID',
                        dataIndex: 'opportunityId',
                        key: 'opportunityId',
                        width: 240,
                        ellipsis: true,
                    },
                    {
                        title: 'Client Name',
                        dataIndex: 'clientName',
                        key: 'clientName',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: 'Opportunity Name',
                        dataIndex: 'opportunityName',
                        key: 'opportunityName',
                        width: 280,
                        ellipsis: true,
                    },
                    {
                        title: 'Sales',
                        dataIndex: 'sales',
                        key: 'sales',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Sales Email',
                        dataIndex: 'salesEmail',
                        key: 'salesEmail',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: 'CSM',
                        dataIndex: 'csm',
                        key: 'csm',
                        width: 170,
                        ellipsis: true,
                    },
                    {
                        title: 'CSM Email',
                        dataIndex: 'csmEmail',
                        key: 'csmEmail',
                        width: 250,
                        ellipsis: true,
                    },
                    {
                        title: 'Contract Type',
                        dataIndex: 'contractType',
                        key: 'contractType',
                        width: 180,
                        ellipsis: true,
                    },
                    {
                        title: 'Margin %',
                        dataIndex: 'marginNumber',
                        key: 'marginNumber',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Risk Buffer',
                        dataIndex: 'riskBufferNumber',
                        key: 'riskBufferNumber',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Probability %',
                        dataIndex: 'probability',
                        key: 'probability',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Start Date',
                        dataIndex: 'startDate',
                        key: 'startDate',
                        width: 190,
                        ellipsis: true,
                    },
                    {
                        title: 'Contract duration (month)',
                        dataIndex: 'contractDuration',
                        key: 'contractDuration',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: 'TCV',
                        dataIndex: 'tcv',
                        key: 'tcv',
                        width: 140,
                        ellipsis: true,
                    },
                    {
                        title: 'CST',
                        dataIndex: 'cst',
                        key: 'cst',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Offering',
                        dataIndex: 'offering',
                        key: 'offering',
                        width: 230,
                        ellipsis: true,
                    },
                    {
                        title: 'Solution',
                        dataIndex: 'solution',
                        key: 'solution',
                        width: 210,
                        ellipsis: true,
                    },
                    {
                        title: 'SPOC',
                        dataIndex: 'spoc',
                        key: 'spoc',
                        width: 170,
                        ellipsis: true,
                    },
                    {
                        title: 'SPOC Email',
                        dataIndex: 'spocEmail',
                        key: 'spocEmail',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: 'Bidding Status',
                        dataIndex: 'biddingStatus',
                        key: 'biddingStatus',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: 'Must Win',
                        dataIndex: 'mustWin',
                        key: 'mustWin',
                        width: 150,
                        ellipsis: true,
                    },
                    {
                        title: '是否重点跟进',
                        dataIndex: 'isFollowUp',
                        key: 'isFollowUp',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'DRB Stage',
                        dataIndex: 'drbStage',
                        key: 'drbStage',
                        width: 150,
                        ellipsis: true,
                    },
                    {
                        title: 'DRB Status',
                        dataIndex: 'drbStatus',
                        key: 'drbStatus',
                        width: 160,
                        ellipsis: true,
                    },
                    {
                        title: 'Delivery Nature',
                        dataIndex: 'deliveryNature',
                        key: 'deliveryNature',
                        width: 220,
                        ellipsis: true,
                    },
                    {
                        title: '项目进展情况',
                        dataIndex: 'projectStatus',
                        key: 'projectStatus',
                        width: 230,
                        ellipsis: true,
                        customHeaderCell: collapseCellFunc,
                    },
                ],
                customHeaderCell: firstHeader,
            },
            {
                title: 'Resource Plan', children: [
                    {
                        title: 'Total HC',
                        dataIndex: 'totalHc',
                        key: 'totalHc',
                        width: 140,
                        ellipsis: true,
                    },
                    {
                        title: '原有/加人',
                        dataIndex: 'resourcePlanType',
                        key: 'resourcePlanType',
                        width: 170,
                        ellipsis: true,
                    },
                    {
                        title: 'DL/SY planned?',
                        dataIndex: 'dlsyPlanned',
                        key: 'dlsyPlanned',
                        width: 180,
                        ellipsis: true,
                    },
                    {
                        title: 'Fulfillment Channel',
                        dataIndex: 'fulfillmentChannel',
                        key: 'fulfillmentChannel',
                        width: 200,
                        ellipsis: true,
                    },
                    {
                        title: '资源计划',
                        dataIndex: 'resourcePlanStatus',
                        key: 'resourcePlanStatus',
                        width: 190,
                        ellipsis: true,
                    },
                    {
                        title: 'Remarks',
                        dataIndex: 'remarks',
                        key: 'remarks',
                        width: 180,
                        ellipsis: true,
                        customHeaderCell: collapseCellFunc,
                    },
                ],
                customHeaderCell: firstHeader,
            },
            {
                title: 'SOM feedback', children: [
                    {
                        title: 'THOR ID',
                        dataIndex: 'thorId',
                        key: 'thorId',
                        width: 170,
                        ellipsis: true,
                    },
                    {
                        title: 'SOM Sales',
                        dataIndex: 'somSales',
                        key: 'somSales',
                        width: 170,
                        ellipsis: true,
                    },
                    {
                        title: 'SOM Sales Email',
                        dataIndex: 'somSalesEmail',
                        key: 'somSalesEmail',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: 'SOM CSM',
                        dataIndex: 'somCsm',
                        key: 'somCsm',
                        width: 170,
                        ellipsis: true,
                    },
                    {
                        title: 'SOM CSM Email',
                        dataIndex: 'somCsmEmail',
                        key: 'somCsmEmail',
                        width: 280,
                        ellipsis: true,
                    },
                    {
                        title: '合同预计签回日期',
                        dataIndex: 'expectedContractReturnDate',
                        key: 'expectedContractReturnDate',
                        width: 210,
                        ellipsis: true,
                    },
                    {
                        title: '项目入场时间',
                        dataIndex: 'projectEntryTime',
                        key: 'projectEntryTime',
                        width: 210,
                        ellipsis: true,
                    },
                    {
                        title: '最新商务进展',
                        dataIndex: 'latestBusinessProgress',
                        key: 'latestBusinessProgress',
                        width: 200,
                        ellipsis: true,
                    },
                    {
                        title: 'SOM Probability%',
                        dataIndex: 'somProbability',
                        key: 'somProbability',
                        width: 220,
                        ellipsis: true,
                    },
                    {
                        title: '备注',
                        dataIndex: 'remark',
                        key: 'remark',
                        width: 240,
                        ellipsis: true,
                    },
                    {
                        title: '信息更新日期',
                        dataIndex: 'infoUpdateDate',
                        key: 'infoUpdateDate',
                        width: 230,
                        customHeaderCell: collapseCellFunc,
                    },
                ],
                customHeaderCell: firstHeader,
            },
            {
                title: 'ARVE Assumption', children: [
                    {
                        title: 'ARVE Assumption HC',
                        dataIndex: 'arveAssumptionHc',
                        key: 'arveAssumptionHc',
                        width: 240,
                        ellipsis: true,
                    },
                    {
                        title: 'ARVE Assumption%',
                        dataIndex: 'arveAssumption',
                        key: 'arveAssumption',
                        width: 240,
                        ellipsis: true,
                    },
                    {
                        title: 'ARVE Assumption Start Date',
                        dataIndex: 'arveAssumptionStartDate',
                        key: 'arveAssumptionStartDate',
                        width: 250,
                        ellipsis: true,
                        customHeaderCell: collapseCellFunc,
                    },
                ],
                customHeaderCell: firstHeader,
            },
            {
                title: '操作信息', children: [
                    {
                        title: '上传人',
                        dataIndex: 'createOperator',
                        key: 'createOperator',
                        width: 200,
                        ellipsis: true,
                    },
                    {
                        title: '上传时间',
                        dataIndex: 'createTime',
                        key: 'createTime',
                        width: 260,
                        ellipsis: true,
                    },
                    {
                        title: '更新人',
                        dataIndex: 'operator',
                        key: 'operator',
                        width: 200,
                        ellipsis: true,
                    },
                    {
                        title: '更新时间',
                        dataIndex: 'operationTime',
                        key: 'operationTime',
                        width: 260,
                        ellipsis: true,
                        customHeaderCell: collapseCellFunc,
                    },
                ],
                customHeaderCell: firstHeader,
            },
            {
                title: '操作',
                dataIndex: 'operation',
                width: 160,
                slots: { customRender: "operation" },
                fixed: "right",
                customHeaderCell: leftHeader,
            },

        ]

        const pagination = reactive({
            current: 1,
            pageSize: 10,
            total: 0,
            showSizeChanger: true,
            showTotal: (total) => {
                return `共 ${total} 条`;
            },
            onChange: (page) => {
                pagination.current = page
                pageChange()
            },
            onShowSizeChange: (_, size) => {
                pagination.pageSize = size;
                pagination.current = 1;
                pageChange()
            }
        })

        const pageChange = async() => {
            let info = {}
            let { current, pageSize } = toRefs(pagination)
            info.pageIndex = current.value
            info.pageSize = pageSize.value
            let res = await $api.matchingInfoListPipeLine(info)
            tableData.value = res.data.resource
            pagination.total = res.data.total
        }

        const resetForm = () => {
            Object.assign(formState, initData)
            pagination.current = 1
            getSelectOptions()
            doSearch()
            updateParams()
        }

        const tableData = ref([])

        const doSearch = async () => {
            updateParams()
            pagination.current = 1
            let res = await $api.matchingInfoListPipeLine({ ...formState, pageIndex: pagination.current, pageSize: pagination.pageSize })
            tableData.value = res.data.resource
            pagination.total = res.data.total
        }

        const moreSeachHanlder = () => {
            doSearch()
            closeMoreForm()
        }

        const addPipelineClick = (type, record) => {
            optType.value = type
            if (type !== 'add') pipelineInfo.value = JSON.parse(JSON.stringify(record))
            pipelineVisible.value = true
        }

        const pipelineVisible = ref(false)
        const optType = ref('')
        const pipelineInfo = ref({})
        const closePipelineModal = () => {
            pipelineInfo.value = {}
            pipelineVisible.value = false
        }
        const submitPipelineModal = () => {
            pipelineInfo.value = {}
            doSearch()
        }

        const { filterObj } = useHandler()
        let currentState = {}
        const updateParams = () => {
            currentState = cloneDeep(formState);
        }
        const exportClick = () => {
            let info = filterObj({ ...currentState })
            $api.exportPipeline(info).then((res) => {
                message.success(res.msg)
            });
        }

        const importModalVisible = ref(false);
        const handleCloseModal = () => {
            importModalVisible.value = false;
        };
        const doUpdateImport = () => {
            doSearch()
        };
        const uploadClick = () => {
            importModalVisible.value = true;
        }

        const paramListObj = reactive({
            accountOps: [],
            subPlOps: [],
            cstOps: [],
            offeringOps: []
        })

        const getSelectOptions = async () => {
            const params = {
                tag: ['Account', 'SubPL', 'Offering', 'Cst'],
                offering: '', sub: '',
                account: '',
                subDouble: [], offeringDouble: [],
                accountDouble: [],
                cstDouble: []
            }
            let res = await $api.returnEnum(params)
            paramListObj.accountOps = dictionaryOpt(res.data.account)
            paramListObj.subPlOps = dictionaryOpt(res.data.SubPL)
            paramListObj.cstOps = dictionaryOpt(res.data.allCst)
            paramListObj.offeringOps = dictionaryOpt(res.data.offering)
        }

        const getOptions = (inputType) => {
            switch (inputType) {
                case 'subPl':
                    return paramListObj.subPlOps
                case 'account':
                    return paramListObj.accountOps
                case 'cst':
                    return paramListObj.cstOps
                case 'offering':
                    return paramListObj.offeringOps
                default:
                    ''  // 如果没
            }
        }

        const changeSelectOptions = async(value,type,options) => {
            let res = await $api.returnEnum(value)
            let result = res?.data[type]
            paramListObj[options] = dictionaryOpt(result)
            // 下面的代码是筛选，因为account对应subPL，所以先选subPL的时候会清掉不符合的account，反之亦然
            if(['accountBySub','account'].includes(type)){
               formState.account = formState.account.filter((item)=>{return result.includes(item)})
            } else if(['SubPLByAccount','SubPL'].includes(type)){
               formState.subPl = []
            } else if(['offeringByCstDouble','offering'].includes(type)){
                formState.offering = formState.offering.filter((item)=>{return result.includes(item)})
            } else if(['cst','allCst'].includes(type)){
               formState.cst = []
            }
        }
        const getPartOptions = async(value,option,type) => {
            const params = {
                tag: value,
                offering: '', sub: '',
                account: '',
                subDouble: [], offeringDouble: [],
                accountDouble: [],
                cstDouble: []
            }
            let res = await $api.returnEnum(params)
            paramListObj[option] = dictionaryOpt(res.data[type])
        }
        const changeFn = {
            subPl() {
                const params = {
                    tag: ['Account'],
                    offering: '', sub: '',
                    account: '',
                    subDouble: formState.subPl, offeringDouble: [],
                    accountDouble: [],
                    cstDouble: []
                }
                let type = formState.subPl.length ? 'accountBySub' : 'account'
                if(!formState.subPl.length){
                    getPartOptions(['SubPL'],'subPlOps','SubPL')
                }
                changeSelectOptions(params,type,'accountOps')
            },
            account() {
                const params = {
                    tag: ['SubPL'],
                    offering: '', sub: '',
                    account: '',
                    subDouble: [], offeringDouble: [],
                    accountDouble: formState.account,
                    cstDouble: []
                }
                let type = formState.account.length ? 'SubPLByAccount' : 'SubPL'
                // 没有的话再获取自身
                if(!formState.account.length){
                    getPartOptions(['Account'],'accountOps','account')
                }
                changeSelectOptions(params,type,'subPlOps')
            },
            cst() {
                const params = {
                    tag: ['Offering'],
                    offering: '', sub: '',
                    account: '',
                    subDouble: [], offeringDouble: [],
                    accountDouble: [],
                    cstDouble: formState.cst
                }
                let type = formState.cst.length ? 'offeringByCstDouble' : 'offering'
                if(!formState.cst.length){
                    getPartOptions(['Cst'],'cstOps','allCst')
                }
                changeSelectOptions(params,type,'offeringOps')
            },
            offering() {
                const params = {
                    tag: ['Cst'],
                    offering: '', sub: '',
                    account: '',
                    subDouble: [], offeringDouble: formState.offering,
                    accountDouble: [],
                    cstDouble: []
                }
                let type = formState.offering.length ? 'cst' : 'allCst'
                if(!formState.offering.length){
                    getPartOptions(['Offering'],'offeringOps','offering')
                }
                changeSelectOptions(params,type,'cstOps')
            }
        }
        const orgChange = (val) => {
            changeFn[val]()
        }

        return {
            moreBtnVisible, isShowFormSortBox, isShowMoreList, checkList,
            showMoreForm, closeMoreForm, openFormSortBox, closeModal, queryList,
            queryFormList, resetForm, doSearch, formState, moreSeachHanlder,
            getOptions,
            orgChange,
            queryFormListHandler,
            addPipelineClick,
            pipelineVisible,
            closePipelineModal,
            optType,
            columns,
            pagination,
            tableData,
            paramListObj,
            btnList,
            pipelineInfo,
            submitPipelineModal,
            exportClick,
            uploadClick,
            updateParams,
            importModalVisible,
            handleCloseModal,
            doUpdateImport,
        }
    },
});
</script>
<style scoped lang="less">
.buttons-wrap {
    :deep(.anticon) {
        font-size: 20px;
    }
}

.ant-table-thead>tr:not(:last-child)>th[colspan] {
    border-bottom: 1px;
}

:deep(.ant-table-hide-scrollbar) {
    scrollbar-color: #ffffff #ffffff !important
}
</style>
