<template>
  <div class="customDrawer" ref="customDrawer">
    <a-drawer :title="titleConfig" :closable="true" :visible="visible" width="38.5vw" @close="closeHandler"
              :getContainer="() => $refs.customDrawer">
      <div class="customize-create">
        <div class="commonCard" v-if="typeValue != 'look'">
          <CardTitle class="card">需求信息</CardTitle>
        </div>
        <div class="commonCardButton" v-if="typeValue == 'look'">
          <CardTitle class="card">需求信息</CardTitle>
          <a-button type="primary" ghost class="button" v-if="typeValue == 'look'" @click="copyHandler"
                    v-auth="['system:custom:copy']">
            <CopyOutlined />复制
          </a-button>
        </div>
        <a-form :model="formState" :rules="formRules" layout="vertical" ref="formRef" class="customize-form">
          <a-row class="demandInfoRow">
            <a-col :span="12" v-if="typeValue == 'look'">
              <div>需求编号:</div>
              <div class="lookInfo">{{ formState.demandNumber }}</div>
            </a-col>
            <a-col :span="12" v-if="typeValue == 'look'">
              <div>需求状态:</div>
              <div class="lookInfo" v-if="formState.progressStatus == '0' || formState.progressStatus == '1'">
                进行中</div>
              <div class="lookInfo" v-if="formState.progressStatus == '2'">已完成</div>
              <div class="lookInfo" v-if="formState.progressStatus == '3'">已关闭</div>
            </a-col>
            <a-col :span="24" v-if="typeValue == 'edit'">
              <div>需求编号:</div>
              <div class="lookInfo">{{ formState.demandNumber }}</div>
            </a-col>
            <a-col :span="12">
              <a-form-item label="需求名称:" name="demandName"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-input v-if="typeValue != 'look'" class="form-item-inline"
                         v-model:value="formState.demandName" placeholder="请输入" allowClear autocomplete="off"
                         :maxLength="50" />
                <div v-if="typeValue == 'look'">{{ formState.demandName }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="客户服务经理:" name="csm"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" :disabled="typeValue == 'look'"
                          v-model:value="formState.csm" placeholder="Name/GGID" show-search allowClear
                          :filter-option="filterOption" :options="customizeOps.csmOps">
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.csm }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="客户名称:" name="customerName"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.customerName"
                          placeholder="请选择" show-search allowClear @change="customerNameChange">
                  <a-select-option v-for="item in customizeOps.requirementNameOps" :key="item.value"
                                   :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.customerName }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="行业线:" name="industryLine"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-input v-if="typeValue != 'look'" class="form-item-inline"
                         v-model:value="formState.industryLine" :disabled="true" />
                <div v-if="typeValue == 'look'">{{ formState.industryLine }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="24">
              <a-form-item label="详细客户名称:" name="customerNameDetail"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-input v-if="typeValue != 'look'" class="form-item-inline"
                         v-model:value="formState.customerNameDetail" placeholder="请输入" allowClear
                         autocomplete="off" style="width: 50%" :maxLength="50" />
                <div v-if="typeValue == 'look'">{{ formState.customerNameDetail }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="末级需求部门:" name="demandDeptName"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.demandDeptName"
                          placeholder="请选择" show-search allowClear @change="demandDeptChange">
                  <a-select-option v-for="item in customizeOps.demandDeptNameOps" :key="item.value"
                                   :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.demandDeptName }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="部门层级:" name="deptHierarchy"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-input v-if="typeValue != 'look'" class="form-item-inline"
                         v-model:value="formState.deptHierarchy" :disabled="true" />
                <div v-if="typeValue == 'look'">{{ formState.deptHierarchy }}</div>
              </a-form-item>
            </a-col>
            <a-divider />
            <a-col :span="12">
              <a-form-item label="岗位名称:" name="demandPosition"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.demandPosition"
                          placeholder="请选择" show-search allowClear>
                  <a-select-option v-for="item in customizeOps.demandPositionOps" :key="item.value"
                                   :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.demandPosition }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="人员级别:" name="staffGrade"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.staffGrade" placeholder="请选择"
                          show-search allowClear>
                  <a-select-option v-for="item in customizeOps.staffGradeOps" :key="item.value"
                                   :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.staffGrade }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="工作地点:" name="workLocation"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.workLocation"
                          placeholder="请选择" show-search allowClear>
                  <a-select-option v-for="item in customizeOps.workLocationOps" :key="item.value"
                                   :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.workLocation }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="项目确定性:" name="projectCertainty"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.projectCertainty"
                          placeholder="请选择" show-search allowClear>
                  <a-select-option v-for="item in projectCertaintyOps" :key="item.value"
                                   :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.projectCertainty }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="期望到岗时间:" name="expectArrivalTime"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-date-picker v-if="typeValue != 'look'" placeholder="请选择"
                               v-model:value="formState.expectArrivalTime" format="YYYY-MM-DD" valueFormat="YYYY-MM-DD"
                               style="width: 100%" />
                <div v-if="typeValue == 'look'">{{ formState.expectArrivalTime }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="需求性质:" name="demandNature"
                           :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-select v-if="typeValue != 'look'" v-model:value="formState.demandNature"
                          placeholder="请选择" show-search allowClear style="width: 100%">
                  <a-select-option v-for="item in demandNatureOps" :key="item.value" :value="item.value">
                    {{ item.value }}
                  </a-select-option>
                </a-select>
                <div v-if="typeValue == 'look'">{{ formState.demandNature }}</div>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item :label="typeValue != 'look'?'预计用人时长（月）:':'预计用人时长:'" name="useStaffLength" :class="typeValue == 'look' ? 'customize-formItem labelclass' : 'customize-formItem'">
                <a-input-number v-if="typeValue != 'look'" class="form-item-inline" v-model:value="formState.useStaffLength"
                                allowClear :min="1" :max="12" style="width: 100%" placeholder="请输入"/>
                <div v-if="typeValue == 'look'">{{ formState.useStaffLength }}</div>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
        <a-divider />
        <div class="commonCard" v-if="typeValue == 'edit' || typeValue == 'look'">
          <CardTitle class="card">操作信息</CardTitle>
          <a-row class="demandInfoRow">
            <a-col :span="12">
              <div>创建人：<div class="lookInfo">{{ formState.createOperator }}</div>
              </div>
            </a-col>
            <a-col :span="12">
              <div>创建时间：<div class="lookInfo">{{ formState.createTime }}</div>
              </div>
            </a-col>
            <a-col :span="12">
              <div>更新人：<div class="lookInfo">{{ formState.operator }}</div>
              </div>
            </a-col>
            <a-col :span="12">
              <div>更新时间：<div class="lookInfo">{{ formState.operationTime }}</div>
              </div>
            </a-col>
          </a-row>
        </div>
      </div>
      <div class="btn-row bottom-btn">
        <div class="left"></div>
        <div class="right">
          <a-space>
            <a-button type="primary" ghost @click="closeHandler">取消</a-button>
            <a-button type="primary" v-if="typeValue != 'look'" @click="confirmHandler">确定</a-button>
            <a-button type="primary" v-if="typeValue == 'look' && (statusValue == '1' || statusValue == '0')"
                      @click="editForm" v-auth="['system:custom:modify']">修改</a-button>
          </a-space>
        </div>
      </div>
    </a-drawer>
  </div>
</template>
<script>
import { CopyOutlined } from "@ant-design/icons-vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { defineComponent, reactive, onMounted, ref } from "vue";
import { dictionaryOpt, dictionaryOptions, dictionaryOptionsCode } from "@/utils/common";
import { cloneDeep } from "lodash";
import { message } from "ant-design-vue";
import CardTitle from "@/components/CardTitle.vue";
export default defineComponent({
  name: "customDrawer",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CardTitle,
    CopyOutlined
  },

  emits: ["closeDrawer", 'changeTypeCopy', 'editHandler', 'refresh', 'addFormRules'],
  setup(_, { emit }) {
    const { $api } = useGlobalPropertyHook();
    const closeHandler = () => {
      formRef.value.resetFields()
      emit("closeDrawer")
    }
    let formState = reactive({
      demandName: "",
      csm: undefined,
      customerName: undefined,
      industryLine: "",
      customerNameDetail: "",
      demandDeptName: undefined,
      deptHierarchy: "",
      demandPosition: undefined,
      staffGrade: undefined,
      workLocation: undefined,
      projectCertainty: undefined,
      expectArrivalTime: "",
      demandNature: undefined,
      useStaffLength: "",
    })
    let formRules = reactive({})
    const filterOption = (input, option) => {
      if (!input) return false;
      let reg = /[\s|/,|/，]*/g
      return option.value.replace(reg, '').toUpperCase().indexOf(input.replace(reg, '').toUpperCase()) >= 0 || option.ggid.toUpperCase().indexOf(input.toUpperCase()) >= 0;
    }
    const customizeOps = reactive({
      requirementNameOps: [],
      departmentLevelOps: [],
      demandDeptNameOps: [],
      demandPositionOps: [],
      staffGradeOps: [],
      workLocationOps: [],
      csmOps: []
    })
    onMounted(() => {
      getCustomizeSelectOptions()
      getCustomizeDictionary()
    })
    const getCustomizeSelectOptions = () => {
      $api.getRmPowerInfo().then((res) => {
        customizeOps.requirementNameOps = dictionaryOpt(res.data.account)
        customizeOps.departmentLevelOps = res.data.orgThreeTwoList
        customizeOps.demandDeptNameOps = dictionaryOpt(res.data.orgFourOneOne)
      });
    }
    const getCustomizeDictionary = async () => {
      const result = await Promise.all([$api.getDictionaryList({ params: { dictType: "role,city,grade", }, }), $api.authorizedPerson()])
      customizeOps.demandPositionOps = dictionaryOptions(result[0].data.role)
      customizeOps.staffGradeOps = dictionaryOptionsCode(result[0].data.grade)
      customizeOps.workLocationOps = dictionaryOptionsCode(result[0].data.city)
      customizeOps.csmOps = result[1].data.map(item => {
        return {
          ...item,
          value: item.full_name + '（' + item.email + '）'
        }
      })
    }
    const customerNameChange = (value) => {
      if (value) {
        $api.getAccountPl(value).then((res) => {
          formState.industryLine = res.data.accountPl[0]
        });
      } else {
        formState.industryLine = ''
      }
    }
    const demandDeptChange = (value) => {
      if (value) {
        $api.getThreeTwoList(value).then((res) => {
          formState.deptHierarchy = res.data.orgThreeTwoList[0]
        });
      } else {
        formState.deptHierarchy = ''
      }
    }
    const projectCertaintyOps = dictionaryOpt(['Firm', 'Proposal'])
    const demandNatureOps = dictionaryOpt(['新增', '顶替'])
    let formRef = ref()
    const confirmHandler = () => {
      if (typeValue.value != 'look') {
        formRef.value.validate().then(async () => {
          formState.csmId = customizeOps.csmOps.filter(item => (item.full_name + '（' + item.email + '）') === formState.csm.trim())[0].ggid
          let obj = cloneDeep(formState)
          delete obj.type
          delete obj.createOperator
          delete obj.createTime
          delete obj.operator
          delete obj.operationTime
          delete obj.isClose
          if (typeValue.value == 'create') {
            delete obj.id
          }
          await $api.customAdded(obj)
          if (typeValue.value == 'create') {
            message.success("新建自定义需求成功")
          } else if (typeValue.value == 'edit') {
            message.success("更新成功")
          }
          closeHandler()
          emit('refresh')
        })
      }
    }
    const typeValue = ref('')
    const statusValue = ref('')
    const titleConfig = ref('')
    const handeler = (obj) => {
      if (obj.type != 'create') {
        Object.assign(formState, obj.row, { customerName: obj.row.clientName })
        if (obj.type == 'look') {
          titleConfig.value = '查看自定义需求'
        } else {
          formState.useStaffLength = formState.oldUseStaffLength
          titleConfig.value = '需求详情'
        }
      } else {
        let obj = {
          demandName: "",
          csm: undefined,
          customerName: undefined,
          industryLine: "",
          customerNameDetail: "",
          demandDeptName: undefined,
          deptHierarchy: "",
          demandPosition: undefined,
          staffGrade: undefined,
          workLocation: undefined,
          projectCertainty: undefined,
          expectArrivalTime: "",
          demandNature: undefined,
          useStaffLength: "",
        }
        Object.assign(formState, obj)
        titleConfig.value = '新建自定义需求'
      }
      typeValue.value = obj.type
      statusValue.value = obj.row.progressStatus
    }
    const copy = (obj) => {
      emit('addFormRules')
      Object.assign(formState, obj)
      titleConfig.value = '新建自定义需求'
      typeValue.value = obj.type
    }
    const editForm = () => {
      emit('addFormRules')
      let obj = cloneDeep(formState)
      delete obj.type
      delete obj.createOperator
      delete obj.createTime
      delete obj.operator
      delete obj.operationTime
      delete obj.isClose
      emit("editHandler", obj)
    }
    const copyHandler = () => {
      let obj = cloneDeep(formState)
      delete obj.id
      delete obj.type
      delete obj.createOperator
      delete obj.createTime
      delete obj.operator
      delete obj.operationTime
      delete obj.isClose
      emit("changeTypeCopy", obj)
    }
    return {
      closeHandler,
      formState,
      formRules,
      filterOption,
      customizeOps,
      customerNameChange,
      demandDeptChange,
      projectCertaintyOps,
      demandNatureOps,
      formRef,
      confirmHandler,
      handeler,
      typeValue,
      titleConfig,
      editForm,
      copyHandler,
      copy,
      statusValue
    }
  },
})
</script>
<style scoped lang="less">
//.customDrawer {
//    :deep(.ant-drawer-content) {
//        padding-bottom: 0px !important;
//    }
//
//    .footer {
//        margin-top: 20px;
//        padding-right: 20px;
//        display: flex;
//        justify-content: flex-end
//    }
//}
//
.customize-create {

  .customize-form {

    .customize-formItem {
      width: 90%;
    }
  }
}

.commonCard {
  width: 100%;
  padding: 0.625vw 1.25vw 0;

  .card {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin: 10px 0 10px 0;

    &::before {
      top: 0.1vw;
    }
  }
}

.commonCardButton {
  width: 100%;
  padding: 0.625vw 1.25vw 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: -0.5vw;

  .button {
    margin-top: 7px;
  }

  .card {
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #4D4D4D;
    margin: 10px 0 10px 0;

    &::before {
      top: 0.1vw;
    }
  }
}

//.left {
//    margin-left: 10px;
//    padding: 10px 0px;
//}

//.right {
//    padding: 10px 0px;
//}

.leftTop,
.rightTop {
  margin-top: 10px;
}

.lookMore {
  padding-bottom: 10px
}

//.lookInfo {
//    padding: 8px 0px 25px
//}

:deep(.ant-form-item-label > label) {
  white-space: pre;
  margin-bottom: 6px !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.demandInfoRow {
  margin-top: 1vw;
  color: rgba(77, 77, 77, 0.6);
  white-space: pre;
  margin-bottom: 10px !important;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.lookInfo {
  //margin-left: 16px;
  color: #4D4D4D;
  white-space: initial;
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0.2vw 0 0.83vw 0 !important;
}

.labelclass {
  margin-bottom: 0.73vw !important;

  :deep(.ant-form-item-label > label) {
    color: rgba(77, 77, 77, 0.6);
  }

  :deep(.ant-form-item-label) {
    padding: 0;
  }

  :deep(.ant-form-item-control-input) {
    min-height: 0;
  }
}

.customDrawer {
  :deep(.ant-drawer .ant-form) {
    padding: 0 1.25vw !important;
  }
}

:deep(.ant-form-item) {
  font-family: PingFang SC;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  white-space: initial;
}</style>
