<template>
  <div>
    <FilterLayout @doReset="resetForm" @doSearch="doSearch" ref="filterLayoutRef" @showMoreForm="showMoreForm"
                  :moreBtnVisible="moreBtnVisible" :isShowMoreFlag="isShowMoreList">
      <template #customform>
        <a-form ref="formRef" class="form rms-form" layout="vertical" :model="formState">
          <template v-for="(item, index) in queryList" :key="index">
            <template v-if="index <= 3">
              <a-form-item :label="item.code" name="name" class="rms-form-item">
                <template v-if="item.type === '3'">
                  <a-input v-model:value="formState[item.inputDataType]"
                           :placeholder="item.code === 'CSM' ? 'Name/GGID' : 'Name/ID'" />
                </template>
                <template v-if="item.type === '5'">
                  <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                 mode="multiple" showArrow :maxTagCount="2" allowClear
                                 :options="getOptions(item.inputDataType)">
                  </SelectWithAll>
                </template>
              </a-form-item>
            </template>
          </template>
          <MoreFormList :visible="isShowMoreList" @open="openFormSortBox" @close="closeMoreForm"
                        @moreFormSearch="moreSeachHanlder">
            <template v-for="(item, index) in queryList" :key="index">
              <template v-if="index > 3">
                <a-form-item :label="item.code" name="name" class="rms-form-item">
                  <template v-if="item.type === '3'">
                    <a-input v-model:value="formState[item.inputDataType]"
                             :placeholder="item.code === 'CSM' ? 'Name/GGID' : 'Name/ID'" />
                  </template>
                  <template v-if="item.type === '5'">
                    <SelectWithAll v-model:value="formState[item.inputDataType]" placeholder="请选择"
                                   mode="multiple" showArrow :maxTagCount="2" allowClear
                                   :options="getOptions(item.inputDataType)">
                    </SelectWithAll>
                  </template>
                </a-form-item>
              </template>
            </template>
          </MoreFormList>
        </a-form>
      </template>
      <template #operationBtns>
        <div class="buttons-wrap right">
          <Tooltip title="导出">
            <a-button type="primary" ghost @click="exportClick" v-auth="['system:custom:export']">
              <ExportOutlined />
            </a-button>
          </Tooltip>
          <Tooltip title="新建">
            <a-button class="ml12" type="primary" @click="createCustom" v-auth="['system:custom:add']">
              <PlusOutlined />
            </a-button>
          </Tooltip>
        </div>
      </template>
    </FilterLayout>
    <a-table class="table" rowKey="id" ref="tableRef" :scroll="{
            y: '53vh',
            x: 3000,
        }" :columns="columns" :data-source="tableData" :pagination="pagination">
      <template #progressStatus="{ record }">
        <a-tag :class="getClass(record.progressStatus)">
          <div v-if="record.progressStatus == '0' || record.progressStatus == '1'">进行中</div>
          <div v-if="record.progressStatus == '2'">已完成</div>
          <div v-if="record.progressStatus == '3'">已关闭</div>
        </a-tag>
      </template>
      <template #operation="{ record }">
        <a-space class="flex-box">
          <Tooltip title="需求详情">
                        <span class="operationLink underline icon-btn" @click="outLined(record, 'look')"
                              v-auth="['system:custom:check']">
                            <EyeOutlined :style="{ display: 'block' }" />
                        </span>
          </Tooltip>
          <Tooltip title="修改">
                        <span
                            :class="`operationLink underline icon-btn ${(record.progressStatus === '0' || record.progressStatus === '1') ? '' : 'disabled'}`"
                            @click="outLined(record, 'edit')" v-auth="['system:custom:edit']">
                            <FormOutlined :style="{ display: 'block' }" />
                        </span>
          </Tooltip>
          <Tooltip title="停用">
                        <span
                            :class="`operationLink underline icon-btn ${(record.progressStatus === '0' || record.progressStatus === '1') ? 'redLink' : 'disabled'}`"
                            @click="outLined(record, 'stop')" v-auth="['system:custom:deactive']">
                            <MinusCircleOutlined :style="{ display: 'block' }" />
                        </span>
          </Tooltip>
        </a-space>
      </template>
    </a-table>
    <ModalBox v-model="modelValue" :ok="handleOk" :content="content" :showIconCom="showIconCom" />
    <ModalBox v-model="modelValuePlus" :ok="handleOkPlus" :content="contentPlus" :showIconCom="showIconComPlus" />
    <CustomDrawer ref="refType" :visible="visible" @refresh="refreshTable" @closeDrawer="closeDrawerHandler"
                  @changeTypeCopy="changeTypeCopy" @editHandler="editCpns" @addFormRules="addFormRules"/>
    <FormModalBox v-model:visible="isShowFormSortBox" :sortFlag="false" :checkList="checkList" @closeModal="closeModal"
                  @reset="queryFormListHandler"></FormModalBox>
  </div>
</template>
<script>
import { defineComponent, onMounted, reactive, toRefs, ref } from "vue";
import { extraListHandler, useHandler } from '../hooks/hooks'
import MoreFormList from '@/components/MoreFormList'
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { dictionaryOpt, downloadFile } from "@/utils/common";
import Tooltip from '@/components/Tooltip'
import ModalBox from '@/components/ModalBox'
import { message } from "ant-design-vue";
import { ExportOutlined, PlusOutlined, EyeOutlined, FormOutlined, MinusCircleOutlined } from "@ant-design/icons-vue";
import CustomDrawer from "./components/customDrawer.vue";
import FormModalBox from '@/components/FormModalBox'
export default defineComponent({
  name: "custom",
  components: {
    MoreFormList, Tooltip, ExportOutlined,
    PlusOutlined, EyeOutlined, FormOutlined,
    MinusCircleOutlined, ModalBox, CustomDrawer,
    FormModalBox
  },
  setup() {

    const { $api } = useGlobalPropertyHook()
    const { filterObj } = useHandler()
    const {
      moreBtnVisible, isShowFormSortBox, isShowMoreList, checkList,
      showMoreForm, closeMoreForm, openFormSortBox, closeModal, queryList, queryFormList
    } = extraListHandler()

    onMounted(() => {
      queryFormListHandler({ isQueryDefault: 0, isQuery: true })
      getSelectOptions()
      doSearch()
    })

    const getSelectOptions = async () => {
      let [status, others] = await Promise.all([$api.getDictionaryList({ params: { dictType: "matchingInfoStatus" } }), $api.getRmPowerInfo()])
      paramListObj.status = status.data.matchingInfoStatus.map(
          (item, index) => { return { label: item.dictValue, value: item.dictCode, key: index } }
      )
      paramListObj.departmentLevel = dictionaryOpt(others.data.orgThreeTwoList)
      paramListObj.clientName = dictionaryOpt(others.data.account)
    }

    const queryFormListHandler = (data) => {
      queryFormList(data, 'matchingInfoList', 'custom', 3)
    }

    const resetForm = () => {
      pagination.current = 1
      Object.assign(formState, initData)
      doSearch()
    }

    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page
        pageChange()
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        pageChange()
      }
    });

    const tableData = ref([])
    const modelValue = ref(false)
    const modelValuePlus = ref(false)

    const pageChange = async() => {
      let info = {
        type:1
      }
      let { current, pageSize } = toRefs(pagination)
      info.pageIndex = current.value
      info.pageSize = pageSize.value
      let res = await $api.matchingInfoListTwoTypesTable(info)
      tableData.value = res.data.resource
      pagination.total = res.data.total
    }


    const doSearch = async () => {
      let info = filterObj({ ...formState })
      info.pageIndex = 1
      pagination.current = 1
      info.pageSize = pagination.pageSize
      let res = await $api.matchingInfoListTwoTypesTable(info)
      tableData.value = res.data.resource.map(item => {
        return {
          ...item,
          oldUseStaffLength: item.useStaffLength,
          useStaffLength: item.useStaffLength? item.useStaffLength+'个月' : item.useStaffLength,
        }
      })
      pagination.total = res.data.total
    }

    const moreSeachHanlder = () => {
      doSearch()
      closeMoreForm()
    }

    const paramListObj = reactive({
      clientName: [],
      departmentLevel: [],
      status: [],
    })

    const getOptions = (data) => {
      switch (data) {
        case 'clientName':
          return paramListObj.clientName
        case 'departmentLevel':
          return paramListObj.departmentLevel
        case 'status':
          return paramListObj.status
        default:
          ''  // 如果没
      }
    }

    let initData = {
      demandName: '',
      clientName: [],
      csm: '',
      departmentLevel: [],
      status: [],
      type: '1'
    }

    let formState = reactive({ ...initData })

    const createCustom = () => {
      addFormRules()
      visible.value = true
      refType.value.handeler({
        type: 'create', row: {
          demandName: "",
          csm: undefined,
          customerName: undefined,
          industryLine: "",
          customerNameDetail: "",
          demandDeptName: undefined,
          deptHierarchy: "",
          demandPosition: undefined,
          staffGrade: undefined,
          workLocation: undefined,
          projectCertainty: undefined,
          expectArrivalTime: "",
          demandNature: undefined,
          useStaffLength: "",
        }
      })
    }

    const addFormRules = () => {
      refType.value.formRules = {
        demandName: [
          {
            required: true,
            message: "请输入需求名称",
            trigger: "change",
          },
        ],
        csm: [
          {
            required: true,
            message: "请选择客户服务经理",
            trigger: "change",
          },
        ],
        customerName: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: "change",
          },
        ],
        industryLine: [
          {
            required: true,
            message: "请输入行业线",
            trigger: "change",
          },
        ],
        customerNameDetail: [
          {
            required: true,
            message: "请输入详细客户名称",
            trigger: "change",
          },
        ],
        demandDeptName: [
          {
            required: true,
            message: "请选择末级需求部门",
            trigger: "change",
          },
        ],
        deptHierarchy: [
          {
            required: true,
            message: "请输入部门层级",
            trigger: "change",
          },
        ],
        demandPosition: [
          {
            required: true,
            message: "请选择岗位名称",
            trigger: "change",
          },
        ],
        staffGrade: [
          {
            required: true,
            message: "请选择人员级别",
            trigger: "change",
          },
        ],
        workLocation: [
          {
            required: true,
            message: "请选择工作地点",
            trigger: "change",
          },
        ],
        projectCertainty: [
          {
            required: true,
            message: "请选择项目确定性",
            trigger: "change",
          },
        ],
        demandNature: [
          {
            required: true,
            message: "请选择需求性质",
            trigger: "change",
          },
        ],
        expectArrivalTime: [
          {
            required: true,
            message: "请选择期望到岗时间",
            trigger: "change",
          },
        ],
        useStaffLength: [
          {
            required: true,
            pattern: /^(1[0-2]|[1-9])$/,
            message: "请填写1~12之间的整数",
            trigger: "change",
          },
        ],
      }
    }
    const collapseCellFunc = () => {
      return {
        style: {
          "border-right": '5px solid rgb(255, 255, 255)',
        }
      };
    };
    const firstHeader = () => {
      return {
        style: {
          "border-right": '5px solid rgb(255, 255, 255)',
          "border-bottom": '5px solid rgb(255, 255, 255)'
        }
      };
    }
    const leftHeader = () => {
      return {
        style: {
          "border-left": '5px solid rgb(255, 255, 255)',
        }
      };
    }
    const columns = [
      {
        title: 'Requirement Information', children: [
          {
            title: '需求编号',
            dataIndex: 'demandNumber',
            key: 'demandNumber',
            width: 180,
            ellipsis: true,
          },
          {
            title: '需求状态',
            dataIndex: 'progressStatus',
            key: 'progressStatus',
            width: 140,
            ellipsis: true,
            slots: { customRender: "progressStatus" },
          },
          {
            title: '行业线',
            dataIndex: 'industryLine',
            key: 'industryLine',
            width: 190,
            ellipsis: true,
          },
          {
            title: '客户名称',
            dataIndex: 'clientName',
            key: 'clientName',
            width: 160,
            ellipsis: true,
          },
          {
            title: '详细客户名称',
            dataIndex: 'customerNameDetail',
            key: 'customerNameDetail',
            width: 160,
            ellipsis: true,
          },
          {
            title: '客户服务经理',
            dataIndex: 'csm',
            key: 'csm',
            width: 200,
            ellipsis: true,
          },
          {
            title: '末级需求部门',
            dataIndex: 'demandDeptName',
            key: 'demandDeptName',
            width: 180,
            ellipsis: true,
          },
          {
            title: '部门层级',
            dataIndex: 'deptHierarchy',
            key: 'deptHierarchy',
            width: 150,
            ellipsis: true,
          },
          {
            title: '需求名称',
            dataIndex: 'demandName',
            key: 'demandName',
            width: 180,
            ellipsis: true,
          },
          {
            title: '岗位名称',
            dataIndex: 'demandPosition',
            key: 'demandPosition',
            width: 140,
            ellipsis: true,
          },
          {
            title: '项目确定性',
            dataIndex: 'projectCertainty',
            key: 'projectCertainty',
            width: 160,
            ellipsis: true,
          },
          {
            title: '工作地点',
            dataIndex: 'workLocation',
            key: 'workLocation',
            width: 140,
            ellipsis: true,
          },
          {
            title: '人员级别',
            dataIndex: 'staffGrade',
            key: 'staffGrade',
            width: 140,
            ellipsis: true,
          },
          {
            title: '期望到岗时间',
            dataIndex: 'expectArrivalTime',
            key: 'expectArrivalTime',
            width: 180,
            ellipsis: true,
          },
          {
            title: '需求性质',
            dataIndex: 'demandNature',
            key: 'demandNature',
            width: 140,
            ellipsis: true,
          },
          {
            title: '预计用人时长',
            dataIndex: 'useStaffLength',
            key: 'useStaffLength',
            width: 180,
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
          },
        ],
        customHeaderCell: firstHeader,
      },
      {
        title: '操作信息', children: [
          {
            title: '创建人',
            dataIndex: 'createOperator',
            key: 'createOperator',
            width: 150,
            ellipsis: true,
          },
          {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 220,
            ellipsis: true,
          },
          {
            title: '更新人',
            dataIndex: 'operator',
            key: 'operator',
            width: 150,
            ellipsis: true,
          },
          {
            title: '更新时间',
            dataIndex: 'operationTime',
            key: 'operationTime',
            width: 220,
            ellipsis: true,
            customHeaderCell: collapseCellFunc,
          },
        ],
        customHeaderCell: firstHeader,
      },
      {
        title: '操作',
        dataIndex: 'operation',
        width: 150,
        slots: { customRender: "operation" },
        fixed: "right",
        customHeaderCell: leftHeader,
      },

    ]

    const getClass = (value) => {
      if (value == "0" || value == "1") {
        return 'colorBlue'
      } else if (value == "2") {
        return 'colorGreen'
      } else if (value == "3") {
        return 'colorBrown'
      }
    }

    const exportClick = () => {
      let info = filterObj({ ...formState })
      $api.customExport(info).then((res) => {
        downloadFile(res.file, res.filename);
      })
    }

    const outLined = (row, type) => {
      if (type == 'stop' && ['0', '1'].includes(row.progressStatus)) {
        verifyStop(row)
      }
      if (type == 'edit' && ['0', '1'].includes(row.progressStatus)) {
        editHandler(row)
      }
      if (type == 'look') {
        lookHandler(row)
      }
    }
    const verifyStop = async (row) => {
      let result = await $api.verifyStopRow({
        id: row.id,
        isClose: row.isClose == 0 ? '1' : '0'
      })
      if (result.data) {
        stopIdHandler(row)
      } else {
        contentPlus.value = '该自定义需求下仍有匹配正在处理中，需求关闭后，相关匹配将无法处理。是否确认关闭?'
        showIconComPlus.value = 'ExclamationCircleOutlined'
        modelValuePlus.value = true
        modalData.value = row
      }
    }
    const editHandler = (row) => {
      addFormRules()
      visible.value = true
      refType.value.handeler({ type: 'edit', row: row })
    }

    const lookHandler = (row) => {
      refType.value.formRules = {}
      visible.value = true
      refType.value.handeler({ type: 'look', row: row })
    }
    const refType = ref()

    const stopIdHandler = async (record) => {
      content.value = '是否关闭当前自定义需求?'
      showIconCom.value = 'ExclamationCircleOutlined'
      modalData.value = { record }
      modelValue.value = true
    }

    const handleOk = async () => {
      const { record } = modalData.value
      let info = {
        id: record.id,
        isClose: record.isClose == 0 ? '1' : '0'
      }
      let res = await $api.stopCurrentRow(info)
      message.success(res.msg)
      doSearch()

    }

    const content = ref('')
    const contentPlus = ref('')
    const showIconCom = ref('')
    const showIconComPlus = ref('')
    const modalData = ref()

    const visible = ref(false)

    const closeDrawerHandler = () => {
      visible.value = false
    }

    const handleOkPlus = async () => {
      let info = {
        id: modalData.value.id,
        isClose: modalData.value.isClose == 0 ? '1' : '0'
      }
      let res = await $api.secondClose(info)
      message.success(res.msg)
      doSearch()
    }

    const changeTypeCopy = (val) => {
      refType.value.copy({ type: 'create', row: val })
    }

    const editCpns = (val) => {

      refType.value.handeler({ type: 'edit', row: val })
    }

    const refreshTable = () => {
      doSearch()
    }





    return {
      moreBtnVisible, isShowFormSortBox, isShowMoreList, checkList,
      showMoreForm, closeMoreForm, openFormSortBox, closeModal, queryList, queryFormList,
      resetForm, doSearch, formState, moreSeachHanlder, getOptions, pagination, tableData, createCustom,
      columns, getClass, exportClick, outLined, modelValue, handleOk, showIconCom, showIconComPlus,
      content, contentPlus, visible, closeDrawerHandler, modelValuePlus, handleOkPlus,
      refType, changeTypeCopy, editCpns, refreshTable, queryFormListHandler,addFormRules
    }
  },
});
</script>
<style scoped lang="less">
.buttons-wrap {
  :deep(.anticon) {
    font-size: 20px;
  }
}

.colorBlue {
  cursor: pointer;
  font-size: 0.729vw;
  padding: 0.1vw 0.86vw;
  border: 0;
  color: #1890FF;
  background: #DBEEFF;
}

.colorGreen {
  cursor: pointer;
  font-size: 0.729vw;
  padding: 0.1vw 0.86vw;
  border: 0;
  color: #58B99E;
  background: #DDF8F0 60%;
}

.colorBrown {
  cursor: pointer;
  font-size: 0.729vw;
  padding: 0.1vw 0.86vw;
  border: 0;
  color: #999999;
  background: #CCCCCC 60%;
}

.icon-btn {
  display: flex;
  width: 24px;
  height: 24px;

  :deep(svg) {
    width: 100%;
    height: 100%;
  }
}

:deep(.disabled) {
  cursor: auto;

  .anticon {
    color: #999 !important;

    svg {
      color: #999 !important;
    }
  }
}

:deep(.ant-table-thead > tr:first-child > th) {
  // border: 1px solid rgb(231, 227, 227);
}

.ant-table-thead>tr:not(:last-child)>th[colspan] {
  border-bottom: 1px;
}

:deep(.ant-table-hide-scrollbar) {
  scrollbar-color: #ffffff #ffffff !important
}
</style>
